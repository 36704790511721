import { LoginEntity } from "../model/login";
import config from '../../loginConfig.json'

export const isValidLogin = async (loginInfo: LoginEntity): Promise<boolean> =>{
    const url = config.loginUrl+"/exops/users/login";
    loginInfo.password = window.btoa(loginInfo.password)
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(loginInfo) // body data type must match "Content-Type" header
    });
    const obj = await response.json();
    localStorage.setItem("sessionId",obj.sessionid)
    localStorage.setItem("username",loginInfo.username)
    return response.status===200 ;
}


